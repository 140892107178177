import {ROUTES} from './Routes';
import DashboardIcon from '@material-ui/icons/Dashboard';
import BusinessIcon from '@material-ui/icons/Business';
import PlaylistAddCheckIcon from '@material-ui/icons/PlaylistAddCheck';
import AlarmOnIcon from '@material-ui/icons/AlarmOn';
import HistoryIcon from '@material-ui/icons/History';
import SecurityIcon from '@material-ui/icons/Security';

export const MenuItems = [
        { label: "Dashboard", route: ROUTES.DASHBOARD,  icon: <DashboardIcon />},
        
        { label: "Validazione AML", route: ROUTES.AML_VALIDATION,  icon: <PlaylistAddCheckIcon />},
        { label: "Verifica AML", route: ROUTES.VERIFICATION, icon: <AlarmOnIcon /> },
        { label: "Clienti", route: ROUTES.COMPANIES,  icon: <BusinessIcon />},
        { label: "Vecchi clienti", route: ROUTES.OLD_CLIENTS, icon: <HistoryIcon /> },
        { label: "KYC", link: 'https://kycse.digonos.com/', icon: <SecurityIcon />, route: ''},
    ]
